var config = ({
  firebase: {
    apiKey: "AIzaSyC3v1Oa2vaN53z9aZpHypBGxi-MPU9DyVU",
    authDomain: "biptt-free.firebaseapp.com",
    databaseURL: "https://biptt-free.firebaseio.com",
    projectId: "biptt-free",
    storageBucket: "biptt-free.appspot.com",
    messagingSenderId: "535778913405",
    appId: "1:535778913405:web:ff0b993f5b077f3a735514",
    measurementId: "G-JW0J8SG3FC",
    vapidKey: "BC771jLHp2Wv_ZChx3doxCxgcDkH87Ir3ZPRd35IN_oYdCpD"
  }
})

export default config;
